import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { ButtonGroup } from '@/design-system/atoms/ButtonGroup';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { Title } from '@/design-system/atoms/Title';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';

import styles from './ThreeUpCopy.module.scss';

/**
 * The 3-Up Copy Component is used to achieve a 3 column structure of copy.
 * It supports a title, RTE, and buttons
 */
const ThreeUpCopy = React.forwardRef((props, ref) => {
    const { className, title, columns, ctas, htmlId } = props;

    return (
        <div id={htmlId} ref={ref} className={classnames(styles['three-up-copy'], className)}>
            <ContentContainer className={styles['three-up-copy__wrapper']}>
                {title && (
                    <Title
                        tag={Title.TAG.H2}
                        variant={Title.VARIANT.H3}
                        content={title}
                        className={styles['three-up-copy__title']}
                    />
                )}
                {arrayNotEmpty(columns) && (
                    <Grid>
                        {columns.map(({ title, text }, index) => (
                            <GridColumn
                                key={index}
                                colSpan={{ sm: 4, md: 6, lg: 4 }}
                                className={styles['three-up-copy__column']}
                            >
                                {title && (
                                    <Title
                                        tag={Title.TAG.H3}
                                        variant={Title.VARIANT.H4}
                                        content={title}
                                        className={styles['three-up-copy__column-title']}
                                    />
                                )}
                                <div className={styles['three-up-copy__column-content']}>
                                    {text && <RichTextEditor text={text} />}
                                </div>
                                <div className={styles['three-up-copy__spacer']} />
                                <hr className={styles['three-up-copy__divider']} />
                            </GridColumn>
                        ))}
                    </Grid>
                )}
                {arrayNotEmpty(ctas) && (
                    <div className={styles['three-up-copy__actions']}>
                        <ButtonGroup
                            buttons={ctas}
                            normalizedButtons
                            analytics={{
                                componentName: '3up',
                                componentTitle: title,
                            }}
                        />
                    </div>
                )}
            </ContentContainer>
        </div>
    );
});

ThreeUpCopy.displayName = 'ThreeUpCopy';

ThreeUpCopy.propTypes = {
    /**
     * Section title
     */
    title: PropTypes.string,
    /**
     * Array of Rich Text
     */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
        }),
    ),
    /**
     * Footer buttons
     */
    ctas: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    /**
     * Unique component ID
     * */
    htmlId: PropTypes.string,
};

export default ThreeUpCopy;
